import React from 'react';
import {
  getMatchRowColor,
  getMatchStatusAndProgress,
  getMatchTimeFinishedInfo,
} from 'app/components/match/matchRow/matchRowHelpers';
import {COLORS, Image, SIZES, Typography} from '@fupa/fupa-uikit';
import {convertToRoman} from 'app/helpers/convertToRoman';
import {Emblem} from 'app/components/icons';
import styled from 'styled-components';
import {MatchRowKickoff} from 'app/components/match/matchRow/MatchRowKickoff';
import {MatchRowStanding} from 'app/components/match/matchRow/MatchRowStanding';
import {MatchRowAction} from 'app/components/match/matchRow/MatchRowAction';
import {LinkOnComponent} from 'app/components/links/link';
import {MatchRowStatusBar} from 'app/components/match/matchRow/MatchRowStatusBar';

const SCMatchRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 452px;
  min-width: 300px;
  width: 100%;
  flex-basis: 100%;
  ${props => props.standalone && 'margin: auto;'}
  ${props => props.theme.streamLayoutOneColBig`
      min-width: 360px;
   `}

  ${props =>
    props.theme.desktopLayout`
    ${props =>
      !props.standalone &&
      `
      width: auto;
      flex: 49%;
      flex-grow: 0;
    `}
  `}
`;

const Box = styled.div`
  display: flex;
  height: 110px;
  border-radius: 4px;
  border: 1px solid ${COLORS.lightGrey};
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 0.75rem 0.75rem 0.5rem 1rem;
`;

const Teams = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TeamWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const EmblemContainer = styled.div`
  width: ${SIZES[36]};
  height: ${SIZES[36]};
`;

const Name = styled.div`
  display: flex;
  margin-left: 0.75rem;
  flex: 1;
  min-width: 0;
`;

const SCTypography = styled(Typography)`
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const FullName = styled(SCTypography)`
  display: none;
  @media only screen and (min-width: 450px) {
    display: block;
  }
`;

const MiddleName = styled(SCTypography)`
  @media only screen and (min-width: 450px) {
    display: none;
  }
`;

const Team = ({match, isHomeTeam}) => {
  const team = isHomeTeam ? match?.homeTeam : match?.awayTeam;
  const teamNameAlt = team?.name?.full ?? team?.name?.middle ?? team?.placeholder ?? '';
  const fallbackEmblem = <Emblem style={{color: COLORS.lightGrey, fontSize: 36}} />;
  let name;

  const emblem = team?.image ? (
    <Image alt={teamNameAlt} entity='emblem' src={team.image} width={36} height={36} fit={true} />
  ) : (
    fallbackEmblem
  );

  if (!team) {
    const fallbackName =
      isHomeTeam && match?.homeTeamName ? match.homeTeamName : match?.awayTeamName ? match.awayTeamName : 'Unbekannt';

    name = (
      <Typography variant='subheading' useOverflow>
        {fallbackName}
      </Typography>
    );
  } else {
    const teamLevel =
      team.level > 1 ? <Typography variant='subheading'>&nbsp;{convertToRoman(team.level)}</Typography> : null;

    name = (
      <>
        <FullName variant='subheading'>{teamNameAlt}</FullName>
        <MiddleName variant='subheading'>{team.name?.middle ?? team.placeholder}</MiddleName>
        {teamLevel}
      </>
    );
  }

  return (
    <TeamWrapper isHomeTeam={isHomeTeam}>
      <EmblemContainer>{emblem}</EmblemContainer>
      <Name>{name}</Name>
    </TeamWrapper>
  );
};

const Standing = ({matchStatus, match, dynamicDateformat, label}) => {
  const hasVerdict = match.flags?.includes('verdict');
  if (match.flags?.includes('cancelled') || match.flags?.includes('aborted')) {
    return null;
  } else if (!hasVerdict && (matchStatus === 'PRE_STATUS' || matchStatus === 'LIVE_NO_TICKER_STATUS')) {
    return <MatchRowKickoff match={match} dynamicDateformat={dynamicDateformat} />;
  } else {
    return (
      <MatchRowStanding label={label} matchStatus={matchStatus} awayGoal={match.awayGoal} homeGoal={match.homeGoal} />
    );
  }
};

const MatchRowNew = ({match, colored, ownClubSlug, ownTeamSlug, dynamicDateformat, standalone, caption}) => {
  const MATCH_COLOR = colored ? getMatchRowColor(match, ownClubSlug, ownTeamSlug) : null;
  const {matchStatus, liveProgress} = getMatchStatusAndProgress(match);
  const matchTimeFinishedInfo = getMatchTimeFinishedInfo(match?.flags);

  return (
    <SCMatchRow standalone={standalone}>
      {caption}
      <Box>
        <Content>
          <Wrapper as={LinkOnComponent} to={`/match/${match.slug}`}>
            <Teams>
              <Team match={match} isHomeTeam={true} />
              <Team match={match} isHomeTeam={false} />
            </Teams>
            <Standing
              label={matchTimeFinishedInfo}
              match={match}
              matchStatus={matchStatus}
              dynamicDateformat={dynamicDateformat}
            />
          </Wrapper>
          <MatchRowStatusBar matchStatus={matchStatus} highlightColor={MATCH_COLOR} liveProgress={liveProgress} />
        </Content>
        <MatchRowAction match={match} matchStatus={matchStatus} />
      </Box>
    </SCMatchRow>
  );
};

export {MatchRowNew};
