function updateSnackbarInfo(data) {
  return {
    type: 'SNACKBAR_UPDATE',
    data,
  };
}

function clearSnackbarInfo() {
  return {
    type: 'SNACKBAR_CLEAR',
  };
}


export {updateSnackbarInfo, clearSnackbarInfo}
