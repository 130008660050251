import React from 'react';
import styled from 'styled-components';
import {COLORS, SIZES} from '@fupa/fupa-uikit';

const Progress = styled.span`
  height: 100%;
  display: inherit;
  background-color: ${COLORS.fupaSecondary};
  width: ${props => props.progress}%;
  border-radius: ${SIZES['2']};
  max-width: 100%;
`;

const Bar = styled.div`
  height: ${SIZES['4']};
  border-radius: ${SIZES['2']};
  width: 100%;
  background-color: ${props => props.color};
`;

const MatchProgress = ({variant, color, progress}) => {
  const progressBar = variant === 'live' ? <Progress progress={progress} /> : null;
  const colorConfig = {
    live: () => COLORS.lightGrey,
    highlighted: color => color,
    default: () => 'transparent',
  };
  const colorCode = colorConfig[variant](color);
  return <Bar color={colorCode}>{progressBar}</Bar>;
};

const MatchRowStatusBar = ({matchStatus, highlightColor, liveProgress}) => {
  // Progressbar
  const state = {
    LIVE_STATUS: () => <MatchProgress progress={liveProgress} variant='live' />,
    POST_STATUS: () => <MatchProgress variant={!!highlightColor ? 'highlighted' : 'default'} color={highlightColor} />,
  };
  return state[matchStatus]?.() ?? null;
};

export {MatchRowStatusBar};
