const pushStatus = (state, textOnly) => {
  const action = textOnly
    ? null
    : {
        label: 'Einstellungen',
        link: '/my/following',
        target: 'pwa',
      };

  return {
    message: `Push-Benachrichtigungen ${state}.`,
    action,
  };
};

const unauthorizedFollow = (name, actionText = 'Folgen von', isFollow) => {
  const defaultMessage = `${actionText} ${name} fehlgeschlagen.`;
  let extendedMessage = `${defaultMessage} Du musst angemeldet sein.`;

  if (isFollow) {
    extendedMessage = `${defaultMessage} Melde dich kostenlos an, um direkt alle Infos und Spielereignisse zu erhalten.`;
  }

  return {
    category: isFollow ? 'unauthorized_follow' : 'unauthorized_push',
    message: extendedMessage,
    action: {
      label: 'Anmelden',
      link: `/auth/login?redirectUrl=${encodeURIComponent(window.location.pathname)}`,
    },
  };
};

const followError = name => {
  return {
    message: `${name} kann nicht gefolgt werden. Versuche es später erneut.`,
  };
};

const pushMatchError = {
  message: `Das Spiel kann nicht gepusht werden. Versuche es später erneut.`,
};

const commonUpdateError = {
  message: `Deine Änderung konnte nicht gespeichert werden. Versuche es später erneut.`,
};

const pushError = {
  message: `Unerwarteter Fehler. Versuche es später erneut.`,
};

const limitError = message => {
  return {
    message,
    action: {
      label: 'Favoriten',
      link: '/my/following',
      target: 'pwa',
    },
  };
};

const autoPushSuccess = name => {
  return {
    message: `Du folgst jetzt ${name}. Benachrichtigungen können in den Einstellungen verwaltet werden.`,
    action: {
      label: 'Einstellungen',
      link: '/my/following',
      target: 'pwa',
    },
  };
};

export {
  pushStatus,
  unauthorizedFollow,
  followError,
  pushMatchError,
  pushError,
  commonUpdateError,
  limitError,
  autoPushSuccess,
};
