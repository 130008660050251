import React from 'react';
import styled from 'styled-components';
import {COLORS, Typography} from '@fupa/fupa-uikit';
import {MatchPushButton} from 'app/components/match/MatchPushButton';
import {Button} from '@mui/material';
import {getMatchMedia} from 'app/components/match/matchRow/matchRowHelpers';
import {Sports, ReportProblemOutlined, PlayCircleFilled} from '@mui/icons-material';
import {LinkOnComponent} from 'app/components/links/link';

const VerdictTypography = styled(Typography)`
  &&& {
    color: ${COLORS.darkerGrey};
  }
`;

const StatusInfoWrapper = styled.div`
  display: flex;
  min-height: 3.75rem;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const MatchStatusInfo = ({match, ...props}) => {
  const config = {
    verdict: {
      label: 'Urteil',
      icon: () => <VerdictTypography variant='result'>§</VerdictTypography>,
    },
    cancelled: {
      label: 'Abgesagt',
      icon: () => <ReportProblemOutlined style={{color: '#FF9800'}} />,
    },
    aborted: {
      label: 'Abgebrochen',
      icon: () => <ReportProblemOutlined style={{color: '#FF9800'}} />,
    },
    default: {
      label: 'Abpfiff',
      icon: () => <Sports style={{color: '#616672'}} />,
    },
  };

  const flags = ['verdict', 'aborted', 'cancelled'];

  const statusFlag = flags.find(flag => {
    if (match.flags?.includes(flag)) {
      return flag;
    }
  });

  let {label, icon} = config[statusFlag ?? 'default'];

  return (
    <StatusInfoWrapper {...props}>
      {icon()}
      <Typography variant={'body1'}> {label}</Typography>
    </StatusInfoWrapper>
  );
};

const MediaWrapper = styled.div`
  min-height: 3.75rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${props => props.teaser}) no-repeat;
  background-size: contain;
  background-position: center;
  border-radius: 0.5rem;
`;

const IconCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
`;

const MatchMediaAction = ({match, ...props}) => {
  const {icon, teaser} = getMatchMedia(match.flags);
  const img = match?.gallery?.thumbnail?.path ? match.gallery.thumbnail.path + '300x200.jpeg' : teaser;

  return (
    <MediaWrapper {...props} teaser={img}>
      <IconCircle>{icon}</IconCircle>
    </MediaWrapper>
  );
};

const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const VideoContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  & > :first-child {
    padding-right: 0.25rem;
  }
`;

const Line = styled.span`
  width: 5rem;
  height: 1px;
  background-color: ${COLORS.lighterGrey};
  margin: 0.25rem auto;
`;

const MatchVideoContent = ({matchSlug}) => {
  return (
    <VideoWrapper as={LinkOnComponent} to={`/match/${matchSlug}`}>
      <Line />
      <VideoContent>
        <Typography variant={'caption1'}>+</Typography>
        <PlayCircleFilled style={{color: COLORS.darkGrey, fontSize: 16}} />
        <Typography variant={'caption3'}>Video</Typography>
      </VideoContent>
    </VideoWrapper>
  );
};

const SCAction = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 103px;
  padding: 0.75rem 0.5rem 0.5rem 0.5rem;
  border-left: 1px solid ${COLORS.lighterGrey};
`;

const muiStyles = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
};

const MatchRowAction = ({matchStatus, match}) => {
  const NULL_STATUS =
    match.flags?.includes('cancelled') || match.flags?.includes('aborted') || match.flags?.includes('verdict');

  const MEDIA_STATUS = match.section === 'POST' && match.flags?.includes('gallery');

  const action = {
    push: <MatchPushButton hasLabel component={Button} muiClasses={muiStyles} currentEntityId={match.id} />,
    media: link => <MatchMediaAction as={LinkOnComponent} to={link} match={match} />,
    status: <MatchStatusInfo as={LinkOnComponent} to={`/match/${match.slug}`} match={match} />,
  };

  let actionComponent;

  if (NULL_STATUS) {
    actionComponent = action['status'];
  } else if (matchStatus === 'PRE_STATUS' || match.section === 'LIVE') {
    actionComponent = action['push'];
  } else if (MEDIA_STATUS) {
    let link = `/match/${match.slug}`;
    link = match?.gallery?.slug ? `/photos/${match.gallery.slug}` : link;
    actionComponent = action['media'](link);
  } else {
    actionComponent = action['status'];
  }

  const videoContent = match.flags?.includes('fupatv') ? <MatchVideoContent matchSlug={match.slug} /> : null;

  return (
    <SCAction>
      {actionComponent}
      {videoContent}
    </SCAction>
  );
};

export {MatchRowAction};
