import React from 'react';
import {SIZES, Typography} from '@fupa/fupa-uikit';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  margin-left: 0.25rem;
  flex-direction: column;
  justify-content: space-between;
`;

const Result = styled.div`
  min-width: ${SIZES['16']};
  height: ${SIZES['36']};
  text-align: center;
  letter-spacing: ${SIZES['1.46']};
`;

const SCTypography = styled(Typography)`
  &&& {
    font-size: 1.5rem;
  }
`;

const MatchRowStanding = ({homeGoal, awayGoal, matchStatus, label}) => {
  const variant = matchStatus === 'LIVE_STATUS' ? 'result-running' : 'result';

  return (
    <Container>
      <Result>
        <SCTypography variant={variant}>{homeGoal ?? '-'}</SCTypography>
      </Result>
      {label}
      <Result>
        <SCTypography variant={variant}>{awayGoal ?? '-'}</SCTypography>
      </Result>
    </Container>
  );
};

export {MatchRowStanding};
